import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"
import ReactPlayer from 'react-player'

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <Seo
        title="Buckhead Web Development Atlanta - Day1 - Websuasion"
        ogDescription="Are you looking for a professional web developer serving the Buckhead area of Atlanta? See how we enabled Day1 to achieve their non-profit goals."
        image="/images/case-studies/web-design-pinewood-atlanta-studios.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Buckhead Web Development Atlanta</h1>
						<h3>A Case Study of Day1</h3>
            <p>
							<span className="websuasion">websuasion</span> enabled Day1 to achieve their web development, content management, and web presence goals by providing web development, web design, logo design, photography, and ongoing support.
            </p>
						<p>
							Are you looking for a professional web designer serving the Buckhead Atlanta area to help transform your business? Let's schedule an introductory call.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
        textRight={
          <div>
					<img src="/images/case-studies/buckhead-web-development-atlanta.jpg" className="rounded-image" alt="Buckhead Web Development Atlanta - Websuasion" />
          </div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								<img src="/images/case-studies/day1-logo.webp" alt="Buckhead Web Development Atlanta - Websuasion" />
							</p>
			        <p>
								Day1 is the voice of the mainline Protestant churches, presenting outstanding preachers from the mainline Protestant denominations, including the Cooperative Baptist Fellowship, the Episcopal Church, Evangelical Lutheran Church in America, Presbyterian Church (U.S.A.), United Church of Christ, United Methodist Church, Christian Church (Disciples of Christ), American Baptist Churches, and others. Day1 began broadcasting in 1945 as "The Protestant Hour" and has been on the air every week since, currently on more than 200 stations. Day1 is a ministry of the Alliance for Christian Media, a nonprofit mainline media ministry based in Atlanta, GA.
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="violet">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Client Testimonial</h2>
	            <Blockquote>Our nonprofit media organization has worked with Websuasion and J. Ryan Williams for many years. They keep us on the forefront of technology with outstanding expertise and amazing customer service. Ryan and his team get my highest recommendation.</Blockquote>
							<p>
							- Peter Wallace, President and Executive Producer
							</p>
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/case-studies/day1-peter-wallace.jpg" className="rounded-square" alt="Buckhead Web Development Atlanta - Websuasion" width="300" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h2>Client Needs</h2>
						<ul>
							<li>Custom content management system</li>
			        <li>Web design</li>
							<li>Updated logo</li>
			        <li>Photography</li>
						</ul>
          </div>
        }
        textRight={
          <div>
						<h2>websuasion Solutions</h2>
						<ul>
			        <li>Web application redesign and data migration from previous platform</li>
							<li>Desktop, mobile, and tablet responsive layout</li>
							<li>Logo modernization</li>
			        <li>Photo session highlighting facilities, staff, and production process</li>
						</ul>
          </div>
        }
      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Audio, Video, and Article Content Portal</h2>
							<p>
								Websuasion's president, J. Ryan Williams, first developed the site in 1997, maintaining its codebase and deployment in one form or another ever since. The site has been revamped twice – once in 2009 and most recently in 2016.
							</p>
							<p>
								The most recent update included a new responsive website design and a rich API to help facilitate the monetization of their over 4,000 audio and video assets. This new application structure will allow the organization to grow and introduce mobile apps, content distribution, and numerous pay-per-download features.
							</p>
							<p>
			          <ReactPlayer
			            url= '/files/case-studies/day1.mp4'
			            width='100%'
			            height='100%'
			            controls = {true}
			          />
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Affiliate Download</h2>
							<p>
								The weekly Day1 radio program is distributed to over 200 affiliate stations. The majority download the program through FTP and RSS feed. The Affiliate portal allows Day1 staff to upload different version of the program along with notes. Affiliate stations can then download the upcoming show each week.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Logo Design</h2>
							<p>
								The previous Day1 logo was designed many years ago and utilized drop-shadows and gradients which made it look a little dated. We updated it to a more modern, flat design that works with multiple web and print implementations.
							</p>
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/case-studies/day1-logo.webp" alt="Roswell GA Web Developer - Websuasion" width="300" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="photography">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Photography</h2>
							<p>
								We were asked to provide video and still photography for a pastor recording session which would provide a behind-the-scenes look into their production process.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Ongoing Support</h2>
							<p>
								On a continual basis, we provide server maintenance and hosting of the existing site, as well as general tech consulting for business plans and analytics research.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
